import React, { useState, useEffect, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { Col, Row, Spinner, Modal, Button } from 'react-bootstrap';
import DropDown from 'components/inputs/DropDown';
import { AiFillCaretDown } from 'react-icons/ai';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { DOWNLOAD_GST_VOUCHER } from 'actions/dataReport';
import fileDownload from 'js-file-download';
import { GET_SMC_PAYMENT_GATEWAY, SUBMIT_VOUCHER_STATUS, FETCH_VOUCHER_LIST, VIEW_VOUCHER } from 'actions/smcGateway';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { MdClose } from 'react-icons/md';
import { getTimezoneFromCountryCode } from 'utils/timezone/timezoneUtils';
import CustomDatePickerInput from 'components/inputs/DatePickerInput';
import TextInput from 'components/inputs/Input';
import { toast } from 'react-toastify';

const JobVoucher = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [report, setReport] = useState('Select_Voucher');
  const [fieldValue, setFieldValue] = useState('Select Gateway');
  const [gateway, setGateway] = useState(null);
  const [excelLoading, setExcelLoading] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const [isVoucher, setIsVoucher] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedReportId, setSelectedReportId] = useState(null); // State for the selected report ID
  const [voucherNumber, setVoucherNumber] = useState(''); // State for voucher number
  const [voucherDate, setVoucherDate] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [loadingReportId, setLoadingReportId] = useState(null);
  const [pdfPreviewId, setpdfPreviewId] = useState(null);
  const [matchAmount, setMatchAmount] = useState(''); // State to store match amount
  const [reportTypePreview, setReportTypePreview] = useState('');
  const [voucherTypePreview, setVoucherTypePreview] = useState('');
  const [checkAmount, setCheckAmount] = useState('');

  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const reportList = useSelector((state) => state.smcReports.reports);

  const countryTimezone = getTimezoneFromCountryCode(_.get(profileData, 'country_code', 'UTC'));
  const [startDate, setStartDate] = useState(moment(new Date()).tz(countryTimezone).startOf('day').utc());
  const [endDate, setEndDate] = useState(moment(new Date()).tz(countryTimezone).endOf('day').utc());

  const handleAssignVoucherClick = (reportId, voucherNumber, voucherDate) => {
    setSelectedReportId(reportId); // Set the selected report ID
    setIsDate(voucherDate)
    setIsVoucher(voucherNumber)
    setShowModal(true); // Show the modal
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    setVoucherNumber(''); // Reset the voucher number
  };

  const handleVoucherSubmit = () => {
    dispatch({
      type: 'UPDATE_VOUCHER_NUMBER',
      payload: {
        reportId: selectedReportId,
        voucherNumber,
        voucherDate
      },
    });
    window.location.reload()
    handleModalClose(); // Close the modal after dispatching
  };

  const filterData = [
    { label: 'INCOME VOUCHER', value: 'JV' },
    { label: 'BRV WALLET DEPOSIT', value: 'BRV' },
    { label: 'GST_REPORT', value: 'GST' },
  ];

  const initialValues = {
    from: moment(startDate).tz(countryTimezone).utc(),
    to: moment(endDate).tz(countryTimezone).utc(),
    report: [report],
    gatewayId: gateway,
  };

  const getSMCGateway = useCallback((data) => {
    dispatch({ type: GET_SMC_PAYMENT_GATEWAY.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getSMCGateway();
  }, []);


  //FETCH VOUCHER LIST DAY WISE
  const fetchAllVoucherList = (data) => {

    const today = moment().utc().startOf('day'); // Convert today to UTC start of day
    const start = moment(startDate).utc().startOf('day'); // Convert startDate to UTC start of day
    const end = moment(endDate).utc().startOf('day'); // Convert endDate to UTC start of day

    // Block today from being selected
    if (start.isSame(today, 'day') || end.isSame(today, 'day')) {
      toast.error("Today's date selection is not allowed");
      return; // Stop further execution
    }

    // Check if startDate is greater than endDate
    if (moment(startDate).isAfter(moment(endDate))) {
      toast.error("From date cannot be greater than To date");
      return; // Stop further execution
    }

    const payload = {
      ...data,
      from: moment(startDate).utc().startOf('day'), // 00:00:00.000
      to: moment(endDate).utc().endOf('day'), // 23:59:58.999
      report: initialValues.report,
    };

    if (report === 'BRV') {
      if (fieldValue === 'ICICI BANK') {
        payload.bank_name = 'ICICI';
        payload.gatewayId = 2;
      } else if (fieldValue === 'BillDesk (Kotak Bank)') {
        payload.bank_name = 'Kotak';
        payload.gatewayId = 1;
      } else if (fieldValue === 'HDFC BANK') {
        payload.bank_name = 'HDFC';
      } else if (fieldValue === 'All Bank Detail') {
        payload.bank_name = 'All';
      }
    }

    dispatch({
      type: FETCH_VOUCHER_LIST.REQUEST,
      payload,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success("Voucher Generated Successfully");
            setExcelLoading(false);
          }
        }
      },
    });
  };

  //DOWNLOAD GST REPORT
  const downloadGSTReport = (data) => {
    const payload = {
      ...data,
      from: moment(startDate).tz(countryTimezone).utc(),
      to: moment(endDate).tz(countryTimezone).utc(),
      report: initialValues.report,
    };

    dispatch({
      type: DOWNLOAD_GST_VOUCHER.REQUEST,
      payload: payload,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `gst_report.${payload.type ? 'xlsx' : 'pdf'}`);
            setExcelLoading(false);
          }
        }
      },
    });
  };

  //VIEW PDF PERVIEW
  const viewVoucherPreview = (reportId, type, reportType, totalAmount) => {
    setLoadingReportId(reportId); // Set loading state
    setReportTypePreview(reportType);
    setVoucherTypePreview(type);
    setMatchAmount('');
    setCheckAmount(totalAmount)

    const payload = { reportId };

    dispatch({
      type: VIEW_VOUCHER.REQUEST,
      payload,
      cb: (res) => {
        setLoadingReportId(null); // Reset loading state
        if (res && _.get(res, 'status') === 200) {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
          setShowPdfModal(true);
          setpdfPreviewId(reportId)
        }
      },
    });
  };

  const downloadVoucher = (reportId) => {
    const payload = { reportId };
    dispatch({
      type: VIEW_VOUCHER.REQUEST,
      payload,
      cb: (res) => {
        setLoadingReportId(null); // Reset loading state
        if (res && _.get(res, 'status') === 200) {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          fileDownload(blob, `voucher_status_${reportId}.pdf`);
        }
      },
    });
  };

  //SEND STATUS
  const handleVoucherStatus = (status) => {
    const numericMatchAmount = Number(matchAmount);
    if ((isNaN(numericMatchAmount) || matchAmount === '' || matchAmount === null) && (status === 'approved')) {
      toast.error("Please enter a valid amount.");
      return;
    }

    if ((numericMatchAmount !== checkAmount) && (status === 'approved')) {
      toast.error("Amount Should Match with Total Amount");
      return;
    }

    const payload = {
      reportId: pdfPreviewId,
      status: status, // "Approved" or "Rejected"
      approvedBy: status === 'approved' ? profileData?.name : undefined,
      rejectedBy: status === 'rejected' ? profileData?.name : undefined, // Include rejectedBy if status is "rejected"
      matchAmount: numericMatchAmount, // Send match amount in payload
      reportType: reportTypePreview || '',
      voucherType: voucherTypePreview || '',
      approvalDate: moment(new Date()).tz(countryTimezone).utc() || '',
    };

    dispatch({
      type: SUBMIT_VOUCHER_STATUS.REQUEST,
      payload,
      cb: (res) => {
        if (res && _.get(res, 'status') === 200) {
          if (status === 'rejected') {
            toast.success("Voucher Rejected Successfully");
            setShowPdfModal(false);
            fetchAllVoucherList({});
            return;
          }
          fileDownload(res.data, `voucher_status_${status}.pdf`);
          toast.success("Voucher Approved and Downloaded Successfully");
          setShowPdfModal(false);
          fetchAllVoucherList({});
        }
      },
    });
  };


  useEffect(() => {
    // Clear report list on page load
    dispatch({
      type: FETCH_VOUCHER_LIST.SUCCESS,
      payload: [],
    });
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.jobVoucher')} />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col lg={'auto'}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment().endOf('day')} // Yesterday as default start date
                          initialValueOfEndDate={moment().endOf('day')} // Yesterday as default end date
                          minDate={moment().add(1, 'days').startOf('day')} // Prevent selection of today
                          closeOnSelect={true}
                          timeFormat={false} // Disable time selection
                        />
                      </div>
                    </Col>
                    <Col lg={'auto'}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="job-voucher-filter-block">
                              <div className="job-voucher-filter-sec">
                                <span className="job-voucher-filter-label">{_.startCase(initialValues.report)}</span>
                              </div>
                              <div className="job-voucher-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="customerBooking-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              className="item"
                              onClick={() => {
                                setReport(item.value);
                              }}
                            >
                              {_.startCase(item.label)}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col>
                    {report === 'BRV' && (
                      <Col lg={'auto'}>
                        <DropDown
                          popupClass="main-menu bottom scrollable"
                          trigger={
                            <>
                              <div className="job-voucher-filter-block">
                                <div className="job-voucher-filter-sec">
                                  <span className="job-voucher-filter-label">{fieldValue}</span>
                                </div>
                                <div className="job-voucher-arrow__icon">
                                  <AiFillCaretDown />
                                </div>
                              </div>
                            </>
                          }
                          triggerClass="customerBooking-filter-dropdown"
                        >
                          {[
                            { name: 'BillDesk (Kotak Bank)', id: 1 },
                            { name: 'ICICI BANK', id: 2 },
                            { name: 'HDFC BANK', id: 3 },
                            { name: 'All Bank Detail', id: null },
                          ].map((bank, index) => (
                            <ul key={`${index}`}>
                              <li
                                className="item"
                                onClick={() => {
                                  setFieldValue(bank.name);
                                  setGateway(bank.id); // Remove this line if 'All Bank Detail' shouldn't have gatewayId
                                }}
                              >
                                {bank.name}
                              </li>
                            </ul>
                          ))}
                        </DropDown>
                      </Col>
                    )}
                    {/* PDF Download Button */}
                    {report !== 'GST' && (
                      <Col lg={'auto'} className="excel-icon--block">
                        <Button
                          type="submit"
                          title='Generate Daily Vouchers'
                          className="apply--btn w-100"
                          onClick={() => { fetchAllVoucherList({}) }}
                          disabled={(report === 'Select_Voucher' ? true : false) || (report === 'BRV' && fieldValue === 'Select Gateway' ? true : false)}
                        >
                          {t('Generate Vouchers')}
                        </Button>
                      </Col>
                    )}
                    {/* Excel Download Button */}
                    {report === 'GST' && (
                      <Col lg={'auto'} className="excel-icon--block">
                        {excelLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <Button
                              onClick={() => {
                                downloadGSTReport({ type: 'excel' });
                                setExcelLoading(true);
                              }}
                            >
                                 {t('Download')}
                                </Button>
                          </div>
                        )}
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </Card>
          </div>
          <div className="data-table-wrapper mt-4">
            <h5>{t('Report List')}</h5>
            <table className="record-list-table" id="table-to-xls">
              <thead>
                <tr>
                  <th>{t('Type')}</th>
                  <th>{t('Description')}</th>
                  <th>{t('Date')}</th>
                  <th>{t('Transaction ID')}</th>
                  <th>{t('Status')}</th>
                  <th>{t('Amount')}</th>
                  <th>{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {reportList && reportList.length > 0 ? (
                  reportList.map((item) => (
                    <tr key={item.reportId}>
                      <td>{item.type}</td>
                      <td>{item.description}</td>
                      <td>
                        {moment(item?.dateRange?.from).format('DD-MM-YYYY')}
                      </td>
                      <td>{item.transactionId}</td>
                      <td>{item.status}</td>
                      <td>{item.totalAmount}</td>
                      <td>
                        {item.status === 'approved' || item.status === 'rejected' ? (
                          <button
                            className="job-report-button"
                            onClick={() => downloadVoucher(item.reportId)}
                          >
                            {t('Download')}
                          </button>
                        ) : (
                          <button
                            className="job-report-button"
                            onClick={() => viewVoucherPreview(item.reportId, item.type, item.reportType, item.totalAmount)}
                            disabled={loadingReportId === item.reportId} // Disable button while loading
                          >
                            {loadingReportId === item.reportId ? t('Loading...') : t('View')}
                          </button>
                        )}
                      </td>
                      {(!item.voucherNumber || !item.voucherDate) && (
                        <td style={{ display: 'none' }}>
                          <button
                            className="job-report-button responsive-button"
                            onClick={() => handleAssignVoucherClick(item.reportId, item.voucherNumber ? false : true, item.voucherDate ? false : true)}
                          >
                            {t(item.voucherNumber ? 'Assign Voucher Date' : item.voucherDate ? 'Assign Voucher Number' : 'Assign Voucher Number and Date')}
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      {t('No reports available')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Modal size={'auto'} show={showModal} onHide={handleModalClose} centered className="job-modal">
            <Modal.Header>
              <div className='job-modal-header'>
                <Modal.Title>{t('View Voucher Details')}</Modal.Title>
                <button onClick={handleModalClose}>
                  <MdClose />
                </button>
              </div>
            </Modal.Header>
            <Modal.Body className='job-modal-body'>

              <div className='previewPdf'>

              </div>

              {isVoucher && <input
                type="text"
                className="form-control mt-2"
                value={voucherNumber}
                onChange={(e) => setVoucherNumber(e.target.value)}
                placeholder={t('Enter Voucher Number')}
              />}
              {isDate && <CustomDatePickerInput
                placeholder={t('offerForm.voucherDate')}
                label={t('offerForm.voucherDate')}
                name="voucherDate"
                value={voucherDate}
                onChange={(date) => setVoucherDate(date)}
              />}
            </Modal.Body>
            <Modal.Footer className='job-modal-footer'>
              <Button onClick={handleVoucherSubmit} className='job-report-button'>
                {t('Submit')}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showPdfModal} className="job-modal" onHide={() => setShowPdfModal(false)} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>{t('PDF Preview')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='pdfPreview' style={{ overflow: 'hidden', display: 'flex' }}>
                {pdfUrl ? (
                  <iframe
                    src={pdfUrl}
                    width="100%"
                    height="700px"
                    style={{ border: 'none', transform: 'translateY(-70px)' }}
                    title="PDF Report Preview"
                  ></iframe>
                ) : (
                  <p>{t('Loading PDF...')}</p>
                )}

                <div className='approvalBlock' style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                  <TextInput
                    isRequired
                    label={t('Match Amount')}
                    placeholder={t('Enter Amount')}
                    name="consumerNumber"
                    value={matchAmount}
                    onChange={(e) => setMatchAmount(e.target.value)}
                  />
                  <button
                    className="job-report-button responsive-button"
                    style={{ margin: '10px 0px' }}
                    onClick={() => handleVoucherStatus('approved')}
                  >
                    {t('Approve')}
                  </button>
                  <button
                    className="job-report-button responsive-button"
                    onClick={() => handleVoucherStatus('rejected')}
                  >
                    {t('Reject')}
                  </button>

                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowPdfModal(false)}>
                {t('Close')}
              </Button>
            </Modal.Footer>
          </Modal>



          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default JobVoucher;
