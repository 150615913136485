import React from 'react';
import modules from '../../modules.json';
import * as _ from 'lodash';
import { FaUserFriends, FaCity, FaFileInvoiceDollar, FaUsers } from 'react-icons/fa';
import { MdOutlineMiscellaneousServices, MdSpaceDashboard, MdOutlineRateReview } from 'react-icons/md';
import { RiFileList2Line, RiStackLine, RiChargingPile2Line, RiReservedLine, RiAdminLine } from 'react-icons/ri';
import { BsFillPlugFill, BsCashCoin } from 'react-icons/bs';
import { AiOutlineCar, AiOutlinePieChart } from 'react-icons/ai';
import { FiRefreshCcw, FiActivity } from 'react-icons/fi';
import { HiTemplate } from 'react-icons/hi';
import { BiCalendar } from 'react-icons/bi';
import { TbRecharging } from 'react-icons/tb';
import { AiOutlineMonitor } from 'react-icons/ai';
import { FaHandshake } from 'react-icons/fa';
import { BsPlugFill } from 'react-icons/bs';
import { LiaNetworkWiredSolid } from "react-icons/lia";

import {
  ADMIN,
  GCM_CPO,
  MNM_DEALER,
  SAAS_ADMIN,
  SUPER_ADMIN,
  TENANT_ADMIN,
  GENTARI_CUSTOM_ROLE,
  B2BCLIENT,
  CPO,
  FINANCE,
  DOCO,
  CUSTOMER,
  SUPPORT,
  MD_ADMIN,
  MD_DEALER,
  SUPPORT_ADMIN,
  NETWORK_ADMIN,
  FLEET_MEMBER,
  FLEET_OWNER,
  ZMT_ADMIN,
  FINANCE_ADMIN,
} from 'components/common/constant';

export const isAccessible = (permission, userPermissions, userExcludedModules) => {
  let newPermissions = userPermissions;

  if (userExcludedModules.length > 0) {
    let temp = [];
    userExcludedModules.forEach((element) => {
      if (modules[element.name] !== undefined && Array.isArray(modules[element.name])) {
        temp = [...temp, ...modules[element.name]];
      }
    });
    if (temp.length > 0) {
      newPermissions = newPermissions.filter((per) => temp.includes(per.name) === false);
    }
  }
  if (
    newPermissions.length > 0 &&
    newPermissions.find((usrPer) => (Array.isArray(permission) ? permission.includes(usrPer.name) : usrPer.name === permission)) !== undefined
  ) {
    return true;
  }
  return false;
};

export const fetchSideBarMenu = (t, allMasterSetting) => {
  const sideMenuData = [
    {
      label: t('sidebar.dashboard'),
      key: 'dashboard',
      path: '/dashboard',
      icon: <MdSpaceDashboard />,
      accessUser: [SUPER_ADMIN],
      showSuperAdmin: true,
    },
    {
      key: 'monitoringControl',
      label: t('sidebar.monitoringAndControl'),
      path: '',
      icon: <AiOutlineMonitor />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        FLEET_MEMBER,
        FLEET_OWNER,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
      children: [
        {
          label: t('sidebar.liveChargingSessions'),
          key: 'liveChargingSession',
          path: '/liveChargingSessions',
          icon: <TbRecharging />,
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },

        {
          label: t('sidebar.chargerStatus'),
          key: 'chargerStatus',
          path: '/chargerStatus',
          icon: <RiChargingPile2Line />,
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            MD_ADMIN,
            MD_DEALER,
          ],
        },

        {
          label: t('sidebar.stationsChargers'),
          key: 'stationsList',
          path: '/stations',
          icon: <BsFillPlugFill />,
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
      ],
    },
    {
      key: 'assetManagement',
      label: t('sidebar.assetManagement'),
      path: '',
      icon: <BsPlugFill />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        FLEET_MEMBER,
        FLEET_OWNER,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
      children: [
        {
          label: t('sidebar.addChargingStation'),
          key: 'addChargingStation',
          path: '/addStation',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
        {
          label: t('sidebar.addCharger'),
          key: 'addCharger',
          path: '/addCharger',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
        {
          label: t('sidebar.rfidManagement'),
          key: 'rfidManagement',
          accessUser: [FLEET_MEMBER, FLEET_OWNER],
          children: [
            {
              label: t('sidebar.rfidMaster'),
              key: 'rfidMaster',
              path: '/rfidGroup',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.rfidRequests'),
              key: 'rfidRequests',
              path: '/rfidRequests',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
          ],
        },
        {
          label: 'Tag Management',
          key: 'tagmanagement',
          path: '/tagmanagement',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
      ],
    },
    {
      label: t('sidebar.billingInvoicing'),
      key: 'billingInvoice',
      icon: <FaFileInvoiceDollar />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        FLEET_MEMBER,
        FLEET_OWNER,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
      children: [
        {
          label: t('sidebar.invoiceReport'),
          key: 'invoiceReport',
          path: '/billingInvoice',
          icon: <FaFileInvoiceDollar />,
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
        {
          label: t('sidebar.tariffManagement'),
          key: 'tariffList',
          path: '/tariffs',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
        {
          label: t('sidebar.userGroups'),
          key: 'userGroupList',
          path: '/userGroupManagement',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
        {
          label: t('sidebar.ocpiPartnerGroup'),
          key: 'OCPIPartnerGroup',
          path: '/OCPIPartnerGroup',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
      ],
    },
    {
      label: t('sidebar.fleetManagement'),
      key: 'fleets',
      icon: <FaUsers />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
      children: [
        {
          label: t('sidebar.vehicles'),
          key: 'manageVehicle',
          path: '/vehicles',
          icon: <AiOutlineCar />,
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },

        {
          label: t('sidebar.b2cfleet'),
          key: 'b2cFleet',
          path: '/fleets',
          icon: <FaUsers />,
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
        {
          label: t('sidebar.reserveSlots'),
          key: 'reserveSlots',
          path: '/reserveSlots',
          icon: <RiReservedLine />,
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
          ],
        },
      ],
    },
    {
      label: t('sidebar.roaming'),
      key: 'roaming',
      icon: <FaHandshake />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
      children: [
        {
          label: t('sidebar.ocpiPartner'),
          key: 'ocpiPartyInfo',
          path: '/OCPI-Partner',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
        {
          label: t('sidebar.ocpiSetting'),
          key: 'ocpiSettings',
          path: '/OCPI-Setting',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
        {
          label: t('sidebar.ocpiEmspPartnerChargingReport'),
          key: 'PartnerStationChargingSummary',
          path: '/partnerChargingSummary',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
      ],
    },
    {
      label: t('sidebar.dataReports'),
      key: 'dataReports',
      icon: <AiOutlinePieChart />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        FLEET_MEMBER,
        FLEET_OWNER,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
      children: [
        {
          label: t('sidebar.basicReports'),
          key: 'basicReports',
          accessUser: [''],
          children: [
            {
              label: 'Station Unit Consumption',
              key: 'stationUnitConsumption',
              path: '/stationUnitConsumption',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: ' Public Station Charging History',
              key: 'publicStationChargingHistory',
              path: '/b2cStationChargingHistory',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: ' Private Station Charging History',
              key: 'privateStationChargingSummary',
              path: '/b2bStationChargingHistory',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'Booking History',
              key: 'bookingHistory',
              path: '/bookingHistory',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: ' Station Charging Summary',
              key: 'stationChargingSummary',
              path: '/stationChargingSummary',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'App User Details List',
              key: 'appUserDetailsList',
              path: '/appUserDetailsList',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'User Charging History',
              key: 'userChargingHistory',
              path: '/userChargingHistory',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.ocpiCpoPartnerChargingReport'),
              key: 'ocpiPartnerChargingReport',
              path: '/ocpiPartnerChargingReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.ratingReviewReport'),
              key: 'ratingReviewReport',
              path: '/ratingReviewReport',
              icon: <MdOutlineRateReview />,
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'Wallet Transaction Report',
              key: 'fleetTransactionReport',
              path: '/fleet/transactionReport',
              accessUser: [FLEET_MEMBER, FLEET_OWNER],
            },
            {
              label: ' Charging Session Report',
              key: 'chargingSessionReport',
              path: '/chargingSessionReport',
              accessUser: [FLEET_MEMBER, FLEET_OWNER],
            },
          ],
        },
        {
          label: t('sidebar.advanceReports'),
          key: 'advanceReports',
          accessUser: [''],
          children: [
            {
              label: 'Station DownTime Report',
              key: 'stationDownTimeReport',
              path: '/stationDownTimeReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.capacityUtilization'),
              key: 'capacityutilization',
              path: '/Capacityutilization',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'Plant Load Factor Report',
              key: 'plantLoadFactorReport',
              path: '/plantLoadFactorReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'PCS Utilization',
              key: 'pcsUtilizationReport',
              path: '/PcsUtilizationReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.alldatareport'),
              key: 'alldatareport',
              path: '/Alldatareport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'Download Advance Report',
              key: 'downloadAdvanceReport',
              path: '/downloadAdvanceReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'Customer Wallet Transaction Report',
              key: 'getCustomerWalletTransaction',
              path: '/customerWalletTransaction',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
          ],
        },
        {
          label: t('sidebar.customReports'),
          key: 'customReports',
          accessUser: [''],
          children: [
            {
              label: t('sidebar.jobVoucher'),
              key: 'jobVoucher',
              path: '/jobVoucher',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.difference'),
              key: 'difference',
              path: '/difference',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.voucherAssignment'),
              key: 'voucherAssignments',
              path: '/voucherAssignments',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.accountingReports'),
              key: 'accountingReports',
              path: '/accountingReports',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.rejectedVoucher'),
              key: 'rejectedVoucher',
              path: '/rejectedVoucher',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.ElectriciyBillReport'),
              key: 'ElectriciyBillReport',
              path: '/ElectriciyBillReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.noLoad'),
              key: 'noLoadReport',
              path: '/noLoadReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.HMILCustomChargingReport'),
              key: 'HMILCustomChargingReport',
              path: '/HMILCustomChargingReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },

            {
              label: t('sidebar.paymentStatusReport'),
              key: 'paymentStatusReport',
              path: '/paymentStatusReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.oldGCMReports'),
              key: 'downloadReports',
              path: '/DownloadReports',
              icon: <RiFileList2Line />,
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'AL Vehicle Charging Session Report',
              key: 'vehicleChargingSession',
              path: '/vehicleChargingSession',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: 'AC Meter Reading Report',
              key: 'acMeterReadingReport',
              path: '/acMeterReadingReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
          ],
        },
      ],
    },
    {
      label: t('sidebar.customers'),
      key: 'customerList',
      path: '/customers',
      icon: <FaUserFriends />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        FLEET_MEMBER,
        FLEET_OWNER,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
    },
    {
      label: t('sidebar.sap'),
      key: 'sap',
      path: '/sap',
      icon: <LiaNetworkWiredSolid />,
      accessUser: [SUPER_ADMIN, FINANCE_ADMIN, NETWORK_ADMIN ],
      showSuperAdmin: true,
      children: [
        {
          label: t('sidebar.sap'),
          key: 'sap',
          path: '/sap',
          icon: <LiaNetworkWiredSolid />,
          accessUser: [SUPER_ADMIN, FINANCE_ADMIN, NETWORK_ADMIN ],
          showSuperAdmin: true,
        },
        {
          label: t('sap.ocpiMaster'),
          key: 'ocpiPosting',
          path: '/OcpiPosting',
          icon: <LiaNetworkWiredSolid />,
          accessUser: [SUPER_ADMIN, FINANCE_ADMIN, NETWORK_ADMIN ],
          showSuperAdmin: true,
        },
        {
          label: t('sap.ocpiInvoicing'),
          key: 'ocpiInvoicing',
          path: '/OcpiInvoicing',
          icon: <LiaNetworkWiredSolid />,
          accessUser: [SUPER_ADMIN, FINANCE_ADMIN, NETWORK_ADMIN ],
          showSuperAdmin: true,
        },
      ],
    },
    {
      label: t('sidebar.customers'),
      key: 'customerListAdmin',
      path: '/customers/admin',
      icon: <FaUserFriends />,
      accessUser: [SUPER_ADMIN, SUPPORT_ADMIN ],
      showSuperAdmin: true,
    },
    {
      label: t('sidebar.activityLog'),
      key: 'activityLog',
      path: '/activityLog',
      icon: <FiActivity />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        FLEET_MEMBER,
        FLEET_OWNER,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
    },
    {
      label: 'Admin Operations & Configuration',
      key: 'adminoperations',
      icon: <RiAdminLine />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        FLEET_MEMBER,
        FLEET_OWNER,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
      children: [
        {
          label: 'Operations',
          key: 'operations',
          children: [
            {
              label: 'Manual Corrections',
              key: 'negativemetevalue',
              path: '/Negativemetervalue',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },

            {
              label: 'Faulty Bookings',
              key: 'faultybookings',
              path: '/faultyBookingReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },

            {
              label: 'Account Delete Request',
              key: 'accountDeleteRequest',
              path: '/accountDeleteRequest',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
          ],
        },
       
        {
          label: 'Configuration',
          key: 'configuration',
          children: [
            {
              label: t('sidebar.accessManagement'),
              key: 'accessManagement',
              path: '/accessManagement',
              icon: <FiRefreshCcw />,
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.updateApp'),
              key: 'updateApp',
              path: '/updateApp',
              icon: <FiRefreshCcw />,
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.chargeCoin'),
              key: 'chargeCoin',
              path: '/chargeCoin',
              icon: <BsCashCoin />,
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                FLEET_MEMBER,
                FLEET_OWNER,
                CPO,
                B2BCLIENT,
                FINANCE,
                DOCO,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
          ],
        },
        {
          label: t('sidebar.offers'),
          key: 'offerManagement',
          children: [
            {
              label: t('sidebar.coupons'),
              key: 'coupons',
              path: '/coupons',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.vouchers'),
              key: 'vouchers',
              path: '/vouchers',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.couponUsageReport'),
              key: 'couponUsageReport',
              path: '/couponUsageReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.couponPerformanceReport'),
              key: 'couponPerformanceReport',
              path: '/couponPerformanceReport',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
            {
              label: t('sidebar.voucherLog'),
              key: 'voucherLogs',
              path: '/voucherLog',
              accessUser: [
                ADMIN,
                GCM_CPO,
                MNM_DEALER,
                SAAS_ADMIN,
                TENANT_ADMIN,
                GENTARI_CUSTOM_ROLE,
                B2BCLIENT,
                FINANCE,
                DOCO,
                SUPPORT,
                SUPER_ADMIN,
                SUPPORT_ADMIN,
                NETWORK_ADMIN,
                CUSTOMER,
                MD_ADMIN,
                MD_DEALER,
              ],
            },
          ],
        },
        {
          label: 'Alerts',
          key: 'alerts',
          path: '/alerts',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
      ],
    },
    {
      label: t('sidebar.subscriptions'),
      key: 'subscriptions',
      path: '/subscriptions',
      icon: <RiFileList2Line />,
      accessUser: [SUPER_ADMIN],
      showSuperAdmin: true,
    },
    {
      label: t('sidebar.tenantsManagement'),
      key: 'tenantsManagement',
      path: '/tenantAdmin',
      icon: <FaUserFriends />,
      accessUser: [SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN, ZMT_ADMIN, FINANCE_ADMIN],
      showSuperAdmin: true,
    },
    {
      label: t('sidebar.master'),
      key: 'master',
      icon: <RiStackLine />,
      accessUser: [SUPER_ADMIN, NETWORK_ADMIN, SUPPORT_ADMIN],
      showSuperAdmin: true,
      children: [
        {
          label: t('sidebar.regionManagement'),
          key: 'Region Management',
          icon: <FaCity />,
          accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
          showSuperAdmin: true,
          children: [
            {
              label: t('sidebar.country'),
              key: 'country',
              path: '/country',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.state'),
              key: 'state',
              path: '/state',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.city'),
              key: 'city',
              path: '/city',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.area'),
              key: 'area',
              path: '/area',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
          ],
        },
        {
          label: t('sidebar.vehicleManagement'),
          key: 'Vehicle Management',
          icon: <AiOutlineCar />,
          accessUser: [SUPER_ADMIN, NETWORK_ADMIN, SUPPORT_ADMIN],
          showSuperAdmin: true,
          children: [
            {
              label: t('sidebar.evMake'),
              key: 'evMake',
              path: '/make',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN, SUPPORT_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.evModel'),
              key: 'evModel',
              path: '/model',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN, SUPPORT_ADMIN],
              showSuperAdmin: true,
            },
          ],
        },
        {
          label: t('sidebar.chargingStationManagement'),
          key: 'Charging Station Management',
          icon: <RiStackLine />,
          accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
          showSuperAdmin: true,
          children: [
            {
              label: t('sidebar.stationAccessType'),
              key: 'stationAccessType',
              path: '/stationAccessType',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.amenities'),
              key: 'amenities',
              path: '/amenity',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.electricitySupplier'),
              key: 'electricitySupplier',
              path: '/electricitySupplier',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
          ],
        },
        {
          label: t('sidebar.template'),
          key: 'Template',
          icon: <HiTemplate />,
          accessUser: [SUPER_ADMIN],
          showSuperAdmin: true,
          children: [
            {
              label: t('sidebar.smsTemplate'),
              key: 'smsTemplate',
              path: '/sms',
              accessUser: [SUPER_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.emailTemplate'),
              key: 'emailTemplate',
              path: '/email',
              accessUser: [SUPER_ADMIN],
              showSuperAdmin: true,
            },
          ],
        },
        {
          label: t('sidebar.chargerManagement'),
          key: 'Charger Management',
          icon: <BsFillPlugFill />,
          accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
          showSuperAdmin: true,
          children: [
            {
              label: t('sidebar.oem'),
              key: 'oem',
              path: '/oem',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.connectorType'),
              key: 'connectorType',
              path: '/connectorType',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.chargeSpeed'),
              key: 'chargeSpeed',
              path: '/chargeSpeed',
              accessUser: [SUPER_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.ChargespotModel'),
              key: 'ChargespotModel',
              path: '/ChargeSpot',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.ownershipType'),
              key: 'ownershipType',
              path: '/OwnershipType',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
          ],
        },
        {
          label: t('sidebar.faultmonitoring'),
          key: 'Fault Monitoring',
          icon: <RiStackLine />,
          accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
          showSuperAdmin: true,
          children: [
            {
              label: t('sidebar.chargerState'),
              key: 'chargerState',
              path: '/chargerState',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
            {
              label: t('sidebar.oemErrorCode'),
              key: 'oemErrorCode',
              path: '/oemErrorCode',
              accessUser: [SUPER_ADMIN, NETWORK_ADMIN],
              showSuperAdmin: true,
            },
          ],
        },
        {
          label: t('sidebar.miscellaneous'),
          key: 'Miscellaneous',
          icon: <MdOutlineMiscellaneousServices />,
          accessUser: [SUPER_ADMIN],
          showSuperAdmin: true,
          children: [
            {
              label: t('sidebar.faqs'),
              key: 'faqs',
              path: '/faqs',
              accessUser: [SUPER_ADMIN],
              showSuperAdmin: true,
            },
          ],
        },
        {
          label: t('sidebar.accessManagement'),
          key: ' Access Management',
          icon: <RiStackLine />,
          accessUser: [SUPER_ADMIN],
          showSuperAdmin: true,
          children: [
            {
              label: t('sidebar.role'),
              key: 'role',
              path: '/role',
              accessUser: [SUPER_ADMIN],
              showSuperAdmin: true,
            },
            // {
            //   label: t('sidebar.module'),
            //   key: 'module',
            //   path: '/modules',
            //   accessUser: [SUPER_ADMIN],
            //   showSuperAdmin: true,
            // },
            // {
            //   label: 'Permissions',
            //   key: 'permission',
            //   path: '/permissions',
            //   accessUser: [SUPER_ADMIN],
            // },
          ],
        },
        {
          label: t('sidebar.masterSetting'),
          key: 'Master Setting',
          icon: <RiStackLine />,
          accessUser: [SUPER_ADMIN],
          showSuperAdmin: true,
          children: _.map(
            _.filter(allMasterSetting, (item) => item.key_name === 'rfid_settings'), //|| item.key_name === 'app_settings'
            (data) => {
              return {
                label: _.startCase(data.key_name),
                key: data.key_name,
                path: `/${data.key_name}/${data.id}`,
                accessUser: [SUPER_ADMIN],
                showSuperAdmin: true,
              };
            }
          ),
        },
        // {
        //   label: 'Faq',
        //   key: 'faq',
        //   path: '/faq',
        //   accessUser: [SUPER_ADMIN],
        // },
        // {
        //   label: 'Privacy',
        //   key: 'privacy',
        //   path: '/privacy',
        //   accessUser: [SUPER_ADMIN],
        // },
      ],
    },

    {
      label: t('sidebar.scheduling'),
      key: 'scheduling',
      icon: <BiCalendar />,
      accessUser: [
        ADMIN,
        GCM_CPO,
        MNM_DEALER,
        SAAS_ADMIN,
        TENANT_ADMIN,
        GENTARI_CUSTOM_ROLE,
        FLEET_MEMBER,
        FLEET_OWNER,
        CPO,
        B2BCLIENT,
        FINANCE,
        DOCO,
        SUPPORT,
        SUPER_ADMIN,
        SUPPORT_ADMIN,
        NETWORK_ADMIN,
        CUSTOMER,
        MD_ADMIN,
        MD_DEALER,
      ],
      children: [
        {
          label: t('sidebar.publicUsers'),
          key: 'publicUsers',
          path: '/publicUsers',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
        {
          label: t('sidebar.schedulingSetting'),
          key: 'schedulingSetting',
          path: '/schedulingSetting',
          accessUser: [
            ADMIN,
            GCM_CPO,
            MNM_DEALER,
            SAAS_ADMIN,
            TENANT_ADMIN,
            GENTARI_CUSTOM_ROLE,
            FLEET_MEMBER,
            FLEET_OWNER,
            CPO,
            B2BCLIENT,
            FINANCE,
            DOCO,
            SUPPORT,
            SUPER_ADMIN,
            SUPPORT_ADMIN,
            NETWORK_ADMIN,
            CUSTOMER,
            MD_ADMIN,
            MD_DEALER,
          ],
        },
      ],
    },
    {
      label: t('sidebar.firmwareUpdate'),
      key: 'firmwareUpdate',
      path: '/firmWareUpdate',
      icon: <FiRefreshCcw />,
      accessUser: [SUPER_ADMIN],
      showSuperAdmin: true,
    },

    // !_.isEmpty(notificationPermissions) &&
    //   !isExcludedTenant(SIDEBARMENU_DATA.NOTIFICATION) && {
    //     label: t('sidebar.notification'),
    //     key: 'notification',
    //     icon: <BsBell />,
    //     accessUser: [
    //       ADMIN,
    //       GCM_CPO,
    //       MNM_DEALER,
    //       SAAS_ADMIN,
    //       TENANT_ADMIN,
    //       GENTARI_CUSTOM_ROLE,
    //       FLEET_MEMBER,
    //       FLEET_OWNER,
    //       CPO,
    //       B2BCLIENT,
    //       FINANCE,
    //       DOCO,
    //       SUPPORT,
    //       SUPER_ADMIN,
    //       SUPPORT_ADMIN,
    //       NETWORK_ADMIN,
    //       CUSTOMER,
    //       MD_ADMIN,
    //       MD_DEALER,
    //     ],
    //     children: [
    //       {
    //         label: t('sidebar.general'),
    //         key: 'notification',
    //         path: '/notification',
    //         accessUser: [
    //           ADMIN,
    //           GCM_CPO,
    //           MNM_DEALER,
    //           SAAS_ADMIN,
    //           TENANT_ADMIN,
    //           GENTARI_CUSTOM_ROLE,
    //           FLEET_MEMBER,
    //           FLEET_OWNER,
    //           CPO,
    //           B2BCLIENT,
    //           FINANCE,
    //           DOCO,
    //           SUPPORT,
    //           SUPER_ADMIN,
    //           SUPPORT_ADMIN,
    //           NETWORK_ADMIN,
    //           CUSTOMER,
    //           MD_ADMIN,
    //           MD_DEALER,
    //         ],
    //       },
    //       {
    //         label: t('sidebar.updateFirmware'),
    //         key: 'updateFirmware',
    //         path: '/updateFirmware',
    //         accessUser: [
    //           ADMIN,
    //           GCM_CPO,
    //           MNM_DEALER,
    //           SAAS_ADMIN,
    //           TENANT_ADMIN,
    //           GENTARI_CUSTOM_ROLE,
    //           FLEET_MEMBER,
    //           FLEET_OWNER,
    //           CPO,
    //           B2BCLIENT,
    //           FINANCE,
    //           DOCO,
    //           SUPPORT,
    //           SUPER_ADMIN,
    //           SUPPORT_ADMIN,
    //           NETWORK_ADMIN,
    //           CUSTOMER,
    //           MD_ADMIN,
    //           MD_DEALER,
    //         ],
    //       },
    //       // {
    //       //   label: 'Communication Manager',
    //       //   key: 'communicationManager',
    //       //   path: '/communicationManager',
    //       //   accessUser: [ADMIN, GCM_CPO, MNM_DEALER, SAAS_ADMIN, TENANT_ADMIN,GENTARI_CUSTOM_ROLE, FLEET_MEMBER, FLEET_OWNER, CPO, B2BCLIENT, FINANCE, DOCO, SUPPORT, SUPER_ADMIN,CUSTOMER],
    //       // },
    //     ],
    //   },

    {
      label: t('sidebar.manageFleet'),
      key: 'manageFleet',
      icon: <FaUsers />,
      accessUser: [FLEET_MEMBER, FLEET_OWNER],
      children: [
        {
          label: t('sidebar.manageDriver'),
          key: 'manageDrivers',
          path: '/drivers',
          accessUser: [FLEET_MEMBER, FLEET_OWNER],
        },
        {
          label: t('sidebar.manageVehicle'),
          key: 'manageVehicles',
          path: '/Manage-Vehicles',
          accessUser: [FLEET_MEMBER, FLEET_OWNER],
        },
        {
          label: t('sidebar.rfidList'),
          key: 'rfidlist',
          path: '/fleet/RFID-List',
          accessUser: [FLEET_MEMBER, FLEET_OWNER],
        },
      ],
    },
    // !_.isEmpty(partnerPermissions) &&
    //   !isExcludedTenant(SIDEBARMENU_DATA.PARTNER_MANAGEMENT) && {
    //     label: t('sidebar.partnerManagement'),
    //     key: 'PartnerManagement',
    //     icon: <FaHandshake />,
    //     accessUser: [
    //       ADMIN,
    //       GCM_CPO,
    //       MNM_DEALER,
    //       SAAS_ADMIN,
    //       TENANT_ADMIN,
    //       GENTARI_CUSTOM_ROLE,
    //       FLEET_MEMBER,
    //       FLEET_OWNER,
    //       CPO,
    //       B2BCLIENT,
    //       FINANCE,
    //       DOCO,
    //       SUPPORT,
    //       CUSTOMER,
    //       MD_ADMIN,
    //       MD_DEALER,
    //     ],
    //     children: [
    //       // {
    //       //   label: t('sidebar.cpo'),
    //       //   key: 'cpo',
    //       //   path: '/cpo',
    //       //   accessUser: [
    //       //     ADMIN,
    //       //     GCM_CPO,
    //       //     MNM_DEALER,
    //       //     SAAS_ADMIN,
    //       //     TENANT_ADMIN,
    //       //     GENTARI_CUSTOM_ROLE,
    //       //     FLEET_MEMBER,
    //       //     FLEET_OWNER,
    //       //     CPO,
    //       //     B2BCLIENT,
    //       //     FINANCE,
    //       //     DOCO,
    //       //     SUPPORT,
    //       //     CUSTOMER,
    //       //     MD_ADMIN,
    //       //     MD_DEALER,
    //       //   ],
    //       // },
    //     ],
    //   },
    // {
    //   label: t('sidebar.settings'),
    //   key: 'settings',
    //   path: '/setting',
    //   icon: <AiOutlineSetting />,
    // },
    // {
    //   label: t('sidebar.logout'),
    //   key: 'logout',
    //   path: '/logout',
    //   icon: <MdOutlineLogout />,
    // },
  ];

  return sideMenuData;
};
